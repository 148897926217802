// import Swiper bundle with all modules installed
import { Lazy } from "swiper";
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

import fslightbox from "fslightbox";

//const productDetailCarouselThumbs = new Swiper(".product-gallery--thumbs", {
//    speed: 300,
//    watchSlidesVisibility: true,
//    watchSlidesProgress: true,
//    slidesPerView: 3,
//    spaceBetween: 16,
//    breakpoints: {
//        576: {
//            slidesPerView: 4,
//        },
//    },
//});

const productCarouselFull = new Swiper(".full-swiper", {
    speed: 300,
    slidesPerView: 1,
    loop: true,
    autoplay: {
        delay: 5000,
    },
	lazyLoading: true,
    navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
    },
});



let lastSlide = document.querySelectorAll('.product-gallery-swiper .swiper-slide');

let productImageCarousel;

window.addEventListener("load", function(){
    initProductImageSwiper();
});
window.initProductImageSwiper = function initProductImageSwiper() {
    productImageCarousel = new Swiper(".product-gallery-swiper", {
        speed: 300,
        slidesPerView: 1.5,
        initialSlide: lastSlide.length,
        spaceBetween: 30,
        lazyLoading: true
    });
};

window.addEventListener('changeContentProductDetail', event => {
    productImageCarousel.destroy();
    initProductImageSwiper();
    refreshFsLightbox();
});
